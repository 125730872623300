<template>
  <div>
    <div>
      <b-tabs align="center">
        <b-tab title="Existing Users" active @click="fetchUsers()" />
        <b-tab title="New Users" @click="fetchUsers('new')" />
        <b-tab title="Deleted Users" @click="fetchUsers('deleted')" />
      </b-tabs>
    </div>
    <my-table
      title=""
      :rows="rows"
      :columns="columns"
      :pagination="pagination"
      :is-loading="isLoading"
      :has-export="true"
      :exporting="exporting"
      @handleExport="handleExportUsers"
    >
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'id'">
          <b-button
            class="w-100"
            variant="danger"
            :to="{ name: 'user-details', params: { id: props.row.id } }"
          >
            {{ props.row.id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'business_type'">
          {{ business_types[props.row.business_type] }}
        </span>

        <span v-else-if="props.column.field === 'logpoint'">
          LP {{ logpointsSum(props.row.logpoints) }}
        </span>

        <span v-else-if="props.column.field === 'default_address.complete'">
          {{ props.row.default_address !== null ? props.row.default_address.complete : '' }}
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="'/log-points/' + props.row.id + '/' + props.row.name">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Logpoint History</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleDeleteUser(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else>
          {{ props.row[props.column.field] }}
        </span>
      </template>
    </my-table>
    <b-col cols="12 text-center">
      <b-button variant="danger" :to="{ name: 'dashboard' }" class="mr-1"
        >Back to Dashboard</b-button
      >
    </b-col>
  </div>
</template>

<script>
import axios from '@axios'
import { MyTable } from '@/components'

export default {
  components: {
    MyTable,
  },
  data() {
    return {
      isLoading: false,
      business_types: ['N/A', 'B2B', 'B2C', 'B2B2C'],
      exporting: false,
      columns: [
        {
          label: 'User ID',
          field: 'id',
          thClass: 'text-nowrap align-middle text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
        },
        {
          label: 'Company Name',
          field: 'name',
          thClass: 'text-nowrap align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Company Registration',
          field: 'company_registration',
          thClass: 'text-nowrap align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Address',
          field: 'default_address.complete',
          thClass: 'text-nowrap align-middle text-center',
          tdClass: 'align-middle',
        },
        {
          label: 'Nature of Business',
          field: 'business_type',
          thClass: 'text-nowrap align-middle text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
        },
        {
          label: 'Registered Date',
          field: 'date_added',
          thClass: 'text-nowrap align-middle text-left',
          tdClass: 'align-middle text-left',
          sortable: false,
        },
        {
          label: 'Logpoint Balance',
          field: 'logpoint',
          thClass: 'text-nowrap align-middle text-left',
          tdClass: 'align-middle text-left',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-nowrap align-middle',
          tdClass: 'text-center align-middle',
          sortable: false,
        },
      ],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      rows: [],
      filterBy: '',
    }
  },
  created() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers(filter = '') {
      this.filterBy = filter
      this.isLoading = true
      const response = await axios.get(`/b2bpro-users?filter=${filter}`)
      if (response.status === 200) {
        this.rows = response.data
      }
      this.isLoading = false
    },
    logpointsSum(logpoints) {
      let sum = 0
      logpoints.forEach((logpoint) => {
        if (logpoint.action_type === 'RESTORE') {
          sum += logpoint.logpoint
        } else if (logpoint.action_type === 'DEDUCT') {
          sum -= logpoint.logpoint
        }
      })
      return parseFloat(sum.toFixed(2))
    },
    handleDeleteUser(row) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await axios.delete(`/b2bpro-users/${row}`)
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'User Deleted',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.rows = this.rows.filter((user) => user.id !== row)
          }
        }
      })
    },
    async handleExportUsers(type) {
      this.exporting = true
      const response = await axios.get(
        `/b2bpro-users/export?filter=${this.filterBy}&file_type=${type}`,
        { responseType: 'blob' }
      )
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `b2bpro-users-${new Date().getTime()}.${type}`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.exporting = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
